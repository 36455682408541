<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <AgreModal
      :modal="AddModal"
      :editModal="AddModal"
      @cerrarModal="AddModal = false"
      @child-refresh="refrescarConver = true"
    />
    <CCard>
      <CCardHeader class="text-center bg-dark text-white">
        <b>{{$t('label.unitConvertionList')}} </b>
      </CCardHeader>
      <CCardBody>
        <CRow>
        <CCol sm="12" class="text-right">
          <CButton square color="add" @click="AddModal = true"  
            v-c-tooltip="{  content: $t('label.conversionRule'), placement: 'top'}">
            <CIcon name="cil-playlist-add" />&nbsp; {{$t('label.nueva')}}
          </CButton>
        </CCol>
        
          <!--CCol sm="2">
            <CCard>
              <CCardHeader color="secondary">
                {{$t('label.units')}}
              </CCardHeader>
              <CCardBody>
                <CCol sm="2" class="row">
                  <CListGroup>
                    <CListGroupItem
                      v-for="TpUnid in itemsTpUnid"
                      :key="TpUnid.TpUnitMeasureId"
                      href="#"
                      @click="filtrar(TpUnid.TpUnitMeasureId)"
                    >
                      <li>
                        {{ TpUnid.TpUnitMeasureName }}
                      </li>
                    </CListGroupItem>
                  </CListGroup>
                </CCol>
                <SEGUNDA FILA 
                <CCol sm="2" class="row">
                  <CListGroup>
                    <CListGroupItem
                      v-for="Unid in itemsTpUnid"
                      :key="Unid.UnitMeasureId"
                      href="#"
                      @click="filtrarUnid(Unid.UnitMeasureId)"
                    >
                      <li>
                        {{ Unid.UnitMeasureName }}
                      </li>
                    </CListGroupItem>
                  </CListGroup>
                </CCol>-->
              <!--/CCardBody>
            </CCard>
          </CCol-->
          <!-- data table -->
          <CCol sm="11" lg="12" xl="12">
            <dataTableExtended
              class="align-center-row-datatable"
              :items="itemsUnid"
              :fields="fields"
              column-filter
              :items-per-page="5"
              :noItemsView="tableText.noItemsViewText"
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              hover
              small
              sorter
              pagination
            >
              <template #Status="{item}">
                <td class="text-center">
                  <CBadge :color="getBadge(item.Status)">
                    {{ $t('label.'+item.Status) }}
                  </CBadge>
                </td>
              </template>
              <template #Detalle="{item}">
                <td class="py-2">
                  <CButton
                    color="edit"
                    square
                    size="sm"   
                    v-c-tooltip="{
                      placement:'top-end',
                      content:  $t('label.edit')+' '+$t('label.conversionRule')
                    }"                 
                    @click="AddModal = item"
                  >
                    <CIcon name="pencil" />
                  </CButton>
                </td>
              </template>
            </dataTableExtended>
        </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { DateFormater, tableTextHelpers } from "@/_helpers/funciones";
import AgreModal from "./add-modal";
import General from "@/_mixins/general";

function fields() {
    return [
      { key: "Nro", label: "#",_classes: 'text-center', _style: 'width:3%; text-align:center', filter: false},
      { key: "Tipo", label: this.$t('label.unitType'),  _classes: 'text-uppercase', _style:'width:15%' },
      { key: "Unidad", label: this.$t('label.unitOfOrigin'), _classes: 'text-uppercase', _style:'width:15%'},
      { key: "Equivale", label: this.$t('label.equals'), _classes: 'text-uppercase', _style:'width:10%'},
      { key: "UnitMeasureToName", label: this.$t('label.unitOfDestination'), _classes: 'text-uppercase', _style:'width:15%'},
      { key: "Operador", label: this.$t('label.operator'), _classes: 'text-uppercase', _style:'width:9%'},
      { key: "Usuario", label: this.$t('label.user'), _classes: 'text-uppercase', _style:'width:10%' },
      { key: "Fecha", label: this.$t('label.date'),_classes: 'text-center', _style:'width:9%' },
      { key: "Status", label:this.$t('label.status'), _classes: 'text-center text-uppercase', _style:'width:9%' },
      { key: "Detalle", label: "", _style: 'width:5%; min-width:45px;', sorter: false, filter: false },
    ];
}
//METHOD
function getUnitMeasureConversionList() {
  this.itemsUnid = [];
  this.Loading = true;
  let listado = [];
  this.listadoGlobal = [];
  this.$http
    .get("UnitMeasureConversion-list", {
      UnitMeasureId: "",
      TpUnitMeasureId: "",
    })
    .then((response) => {
      this.listadoGlobal = [...response.data.data];
      listado = this.listadoGlobal;
      this.itemsUnid = listado.map((listado) =>
        Object.assign({}, this.itemsUnid, {
          TpUnitMeasureId: listado.TpUnitMeasureId,
          Tipo: listado.TpUnitMeasureName,
          UnitMeasureFromId: listado.UnitMeasureFromId,
          UnitMeasureToId: listado.UnitMeasureToId,
          UnitMeasureToName: listado.UnitMeasureToName,
          Equivale: listado.Value,
          Nro: listado.Nro,
          ConversionId: listado.ConversionId,
          Unidad: listado.UnitMeasureFromName,
          Operador: listado.Operator,
          Usuario: listado.TransaLogin ? listado.TransaLogin : "N/A",
          Fecha: listado.TransaRegDate
            ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate)
            : "N/A",
          Status: listado.Status,
          FgActConversion: listado.FgActConversion,
          _classes: listado.Status === "INACTIVO" ? "table-danger" : "",
        })
      );
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.Loading = false;
    });
}

function getUnitMeasureTypeList() {
  this.itemsTpUnid = [];
  this.Loading = true;
  this.$http
    .get("TpUnitMeasure-list", { filter: "ALL" })
    .then((response) => {
      this.itemsTpUnid = [...response.data.data];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.Loading = false;
    });
}

function filtrar(id) {
  this.itemsUnid = this.listadoGlobal
    .filter((item) => item.TpUnitMeasureId === id)
    .map((listado) =>
      Object.assign({}, this.itemsUnid, {
        TpUnitMeasureId: listado.TpUnitMeasureId,
        Tipo: listado.TpUnitMeasureName,
        UnitMeasureFromId: listado.UnitMeasureFromId,
        UnitMeasureFromName: listado.UnitMeasureFromName,
        UnitMeasureToId: listado.UnitMeasureToId,
        UnitMeasureToName: listado.UnitMeasureToName,
        Equivale: listado.Value,
        Nro: listado.Nro,
        ConversionId: listado.ConversionId,
        Unidad: listado.UnitMeasureFromName,
        Operador: listado.Operator,
        Usuario: listado.TransaLogin ? listado.TransaLogin : "N/A",
        Fecha: listado.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate)
          : "N/A",
        Status: listado.Status,
        _classes: listado.Status === "INACTIVO" ? "table-danger" : "",
      })
    );
}

//FUNCTION PARA LISTAR LAS UNIDADES
function filtrarUnid(id) {
  this.itemsUnid = this.listadoGlobal
    .filter((item) => item.UnitMeasureId === id)
    .map((listado) =>
      Object.assign({}, this.itemsUnid, {
        TpUnitMeasureId: listado.TpUnitMeasureId,
        Tipo: listado.TpUnitMeasureName,
        UnitMeasureFromId: listado.UnitMeasureFromId,
        UnitMeasureFromName: listado.UnitMeasureFromName,
        UnitMeasureToId: listado.UnitMeasureToId,
        UnitMeasureToName: listado.UnitMeasureToName,
        Equivale: listado.Value,
        Nro: listado.Nro,
        ConversionId: listado.ConversionId,
        Unidad: listado.UnitMeasureFromName,
        Operador: listado.Operator,
        Usuario: listado.TransaLogin ? listado.TransaLogin : "N/A",
        Fecha: listado.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate)
          : "N/A",
        Status: listado.Status,
        _classes: listado.Status === "INACTIVO" ? "table-danger" : "",
      })
    );
}

//DATA
function data() {
  return {
    //VARIABLES
    Loading: false,
    AddModal: false,
    refrescarConver: false,
    itemsUnid: [],
    itemsTpUnid: [],
    listadoGlobal: [],
  };
}

export default {
  mixins: [General],
  data, 
  computed:{
    fields
  },
  methods: {
    getUnitMeasureConversionList,
    getUnitMeasureTypeList,
    filtrar,
    filtrarUnid,
  },
  components: {
    AgreModal,
  },
  watch: {
    refrescarConver: function() {
      if (this.refrescarConver) {
        this.getUnitMeasureConversionList();
        this.refrescarConver = false;
      }
    },
  },
  mounted() {
    this.getUnitMeasureConversionList();
    this.getUnitMeasureTypeList();
  },
};
</script>
<style scoped></style>
